import Spline from "@splinetool/react-spline";
import { useEffect, useState } from "react";
import styled from "styled-components";
import TextTransition, { presets } from "react-text-transition";

export default function App() {
  const [load, setLoad] = useState(true);
  return (
    <div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#FF6A6A",
          overflowX: "hidden",
        }}
      >
        <TextsContainer>
          <Title springConfig={presets.stiff}>
            {load ? "  " : "Creative"}
            <br />
            {load ? "Loading..." : "Developer"}
          </Title>
          {!load && <Code>import YoominKang from "korea-univ";</Code>}
        </TextsContainer>
        <SplineContainer>
          <Spline
            scene="https://prod.spline.design/aITDpg693IULW0DM/scene.splinecode"
            onLoad={() => {
              setTimeout(() => {
                setLoad(false);
              }, 300);
            }}
          />
        </SplineContainer>
      </div>
    </div>
  );
}

const TextsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 100px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  @media only screen and (max-width: 600px) {
    left: 30px;
  }
`;
const SplineContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin-left: 20vw;
  z-index: 1;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    width: 800px;
    margin-left: -10vw;
  }
`;
const Title = styled(TextTransition)`
  color: white;
  font-size: 100px;
  font-weight: 700;
  line-height: 0.8;
  margin-top: 0;
  margin-bottom: 0;
  text-shadow: 10px 10px 30px #00000050;
  @media only screen and (max-width: 768px) {
    font-size: 54px;
  }
`;
const Code = styled.code`
  color: white;
  font-size: 25px;
  width: 550px;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 8px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 0.5px solid rgba(0, 0, 0, 0.21);
  @media only screen and (max-width: 768px) {
    font-size: 17px;
    padding: 5px;
    border-radius: 8px;
    width: 235px;
    margin-top: 15px;
  }
`;
